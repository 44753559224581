<template>
  <template v-if="formVisible">
    <van-nav-bar title="资产查询" />
    <van-form @submit="login">
      <van-field v-model="form.username" type="text"     label="客户号"   placeholder="请填写客户号"   :rules="[{ required: true, message: '请填写客户号' }]"/>
      <van-field v-model="form.password" type="password" label="查询密码" placeholder="请填写查询密码" :rules="[{ required: true, message: '请填写查询密码' }]"/>
      <div style="margin: 16px; background-color: #fff;">
        <van-button block round type="primary" native-type="submit">登录</van-button>
      </div>
    </van-form>
  </template>

  <template v-else>
    <van-nav-bar title="资产明细" left-text="退出" left-arrow @click-left="logout"/>
    <van-cell :title="info.customerName"/>
    <br/>
    <van-cell-group>
      <van-cell title="总资产"   :value="formatNumber(info.totalAssets) +' 元'" />
      <van-cell title="资金余额" :value="formatNumber(info.moneyRemain) +' 元'" />
      <van-cell title="优先资金" :value="formatNumber(info.moneyFirst)  +' 元'" />
      <van-cell title="转入金额" :value="formatNumber(info.moneyIn) +' 元'" />
      <van-cell title="保证金"   :value="formatNumber(info.deposit) +' 元'" />
      <van-cell title="保证金比例" :value="formatNumber(info.depositRate) +' %'" />
    </van-cell-group>
    <br/>
    <van-cell-group>
      <van-cell title="证券市值" :value="formatNumber(info.marketValue) +' 元'" />
      <van-cell title="买入成本" :value="formatNumber(info.cost)    +' 元'" />
      <van-cell title="盈亏余额" :value="formatNumber(info.surplus) +' 元'" />
      <van-cell title="盈亏比例" :value="formatNumber(info.surplusRate) +' %'" />
      <van-cell title="收益率"   :value="formatNumber(info.yield) +' %'" />
    </van-cell-group>
  </template>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant';
import { Notify } from 'vant';

export default {
  name: 'App',
  data(){
    return {
      formVisible: true,
      form: {},
      info:{},
    }
  },
  components: {
    [Toast.name]: Toast,
  },
  methods: {
    // 登录查询
    login() {
      axios.post(
        "/api/wx/customer/balance", 
        this.form
      ).then((res) => {
        let data = res.data;
        if (data.status != "0") {
          Notify({ "type": 'danger', "message": data.mesg});
          return;
        }
        this.info = data.data;
        this.formVisible = false;
      }).catch((error)=>{
        if (error.response && error.response.data) {
          let {data} = error.response;
          Notify({ "type": 'danger', "message": data.mesg || data });
        } else {
          Notify({ "type": 'danger', "message": "后端接口连接异常，请稍后再试" });
        }
      })
    },
    // 返回登录
    logout() {
      this.formVisible = true;
      this.form = {};
      this.info = {};
    },

    formatNumber(value) {
      if (isNaN(value)) {
        return "0.00";
      }
      value = value.toFixed(2);
      const list = value.split('.');
      const prefix = list[0].charAt(0) === '-' ? '-' : '';
      let num = prefix ? list[0].slice(1) : list[0];
      let result = '';
      while (num.length > 3) {
        result = `,${num.slice(-3)}${result}`;
        num = num.slice(0, num.length - 3);
      }
      if (num) {
        result = num + result;
      }
      return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
    },
  },
}
</script>
<style>
  .van-form {
    margin-top: 100px;
  }
  body {
    background-color: #fafafa;
  }
</style>
