import { createApp } from 'vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

import Vant from 'vant';
import 'vant/lib/index.css';

import App from './App.vue'
createApp(App)
.use(Vant)
.use(VueAxios, axios)
.mount('#app')
